import * as SignalR from '@microsoft/signalr';
import chromeExAPI from '@/chromeExAPI';

let ws = null;
let url = null;
let imageLoadCallback = function imageLoadCallback(base64Wemp, x, y, type) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = function () {
      let canvas = document.createElement('canvas');
      canvas.width = x == -1 ? img.width : x;
      canvas.height = y == -1 ? img.height : y;
      let context = canvas.getContext('2d');
      let mimeType = 'image/' + (type == 'jpg' ? 'jpeg' : type);

      context.drawImage(img, 0, 0, x == -1 ? img.width : x, y == -1 ? img.height : y);
      let dataurl = canvas.toDataURL(mimeType);
      resolve(dataurl);
    };
    img.onerror = function () {
      reject(base64Wemp);
    };
    img.src = base64Wemp;
  });
};

export default {
  install(Vue) {
    if (['https://omarket.kr', 'https://hlc.kr', 'http://localhost:8080'].includes(window.location.origin)) {
      url = process.env.VUE_APP_PRODUCT_SOCKET_URL;
    } else {
      url = process.env.VUE_APP_TEST_SOCKET_URL;
    }
    Vue.prototype.ws_connect = function () {
      if (this.$ws?.connection?._connectionState == 'Connected') {
        console.log('연결 되어있음');
        return;
      }
      const setupOption = {
        skipNegotiation: true,
        transport: SignalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => this.authStore.user.accessToken,
        headers: { authorization: 'Bearer ' + this.authStore.user.accessToken },
        withCredentials: true
      };
      ws = new SignalR.HubConnectionBuilder()
        .withUrl(url, setupOption)
        .withAutomaticReconnect([5000, 5000, 10000])
        .configureLogging(SignalR.LogLevel.Information) // 로깅 설정 (선택 사항)
        .build();
      ws.start()
        .then(() => {})
        .catch(error => {
          console.error('연결 실패:', error);
        });
      Vue.prototype.$ws = ws;

      let events = this.$options['echo'];
      if (!events) {
        return;
      }
      Object.keys(events).forEach(function (key) {
        ws.on(key, async (...args) => {
          try {
            return await events[key].bind(this)(args);
          } catch (e) {
            console.error('error methods setupWs', e);
          }
        });
      }, this);
    };

    Vue.mixin({
      echo: {
        UserConnected(payload) {
          this.socketStore.setConnectionId(payload[0]);
          console.log('연결 성공:' + payload);
        },
        UserDisconnected(payload) {
          this.socketStore.setConnectionId(null);
          console.log('연결 끊김:' + payload);
        },
        async ExtentionsProxy(payload) {
          if (payload.length !== 5) {
            console.log('payload Error Length:' + payload.length);
            return;
          }
          if (this.socketStore.connectionId == null) {
            console.log('connection Id null');
            return;
          }
          this.socketStore.proxyRequestModel = payload;
          let responseModel = null;
          switch (payload[0]) {
            case 'GET':
              responseModel = await chromeExAPI.GetMethod(payload[1], payload[2], payload[4]); // URL
              break;
            case 'POST':
              responseModel = await chromeExAPI.PostMethod(
                payload[1],
                payload[2],
                payload[3],
                payload[4]
              );
              break;
            case 'PUT':
              responseModel = await chromeExAPI.PutMethod(
                payload[1],
                payload[2],
                payload[3],
                payload[4]
              );
              break;
            case 'DELETE':
              responseModel = await chromeExAPI.DeleteMethod(
                payload[1],
                payload[2],
                payload[3],
                payload[4]
              );
              break;
            case 'PATCH':
              responseModel = await chromeExAPI.PatchMethod(
                payload[1],
                payload[2],
                payload[3],
                payload[4]
              );
              break;
            case 'MULITPART':
              responseModel = await chromeExAPI.MultipartMethod(payload[1], payload[2], payload[3]);
              break;
            case 'GetBrowserHtml':
              responseModel = await chromeExAPI.GetBrowserHtmlMethod(payload[1], payload[2]);
              break;
          }
          this.socketStore.proxyResponseModel = responseModel;
          return responseModel;
        },
        async ImageBase64Convert(payload) {
          let item = await chromeExAPI.ImageMethod(payload[0]);
          return item;
        },
        async RequestWebpToJpg(payload) {
          let responseData = {
            body: null,
            headers: null
          };
          let responseModel = await chromeExAPI.ImageMethod(payload[0]);
          const type = payload[0].includes('.png') ? 'png' : 'jpg';
          responseData = {
            body: await imageLoadCallback(responseModel.body, payload[1], payload[2], type),
            headers: null
          };
          return responseData;
        },
        async SetLocalStorage(payload) {
          let response = null;
          try {
            localStorage.setItem(payload[0], payload[1]);
            response = localStorage.getItem(payload[0]);
          } catch (ex) {
            console.log(ex);
          }
          return response;
        },
        async GetLocalStorage(payload) {
          let response = null;
          try {
            response = localStorage.getItem(payload[0]);
          } catch (ex) {
            console.log(ex);
          }
          return response;
        },
        NoticeCollectResult(payload) {
          this.socketStore.setCollectList(false, JSON.parse(payload));
          this.socketStore.setType('COLLECT');
          this.socketStore.setFinish('');
        },
        NoticeCollectFinish(payload) {
          if (payload[0] === '상품수집 완료') {
            this.socketStore.setFinish('COLLECT');
            this.socketStore.setType('');
            this.socketStore.setProgress('stop');
          }
        },
        NoticeUploadResult(payload) {
          this.socketStore.setUploadList(false, JSON.parse(payload));
          this.socketStore.setType('UPLOAD');
          this.socketStore.setFinish('');
        },
        NoticeUploadFinish(payload) {
          if (payload[0] === '상품등록 완료') {
            this.socketStore.setFinish('UPLOAD');
            this.socketStore.setType('');
            this.socketStore.setProgress('stop');
          }
        },
        NoticeStockLinkResult(payload) {
          this.socketStore.setStockLinkList(false, JSON.parse(payload));
          this.socketStore.setType('STOCKLINK');
          this.socketStore.setFinish('');
        },
        NoticeStockLinkFinish(payload) {
          if (payload[0] === '재고연동 완료') {
            this.socketStore.setFinish('STOCKLINK');
            this.socketStore.setType('');
            this.socketStore.setProgress('stop');
          }
        },
        async ExtensionsCookie(payload) {
          const res = await chromeExAPI.CheckSiteLogin(payload);
          return res.responseMsg;
        },
        NoticeStatusChangeResult(result) {
          let deleteResult = {};
          result.forEach(res => {
            deleteResult = { ...deleteResult, ...JSON.parse(res) };
          });
          this.socketStore.setDeleteResult(deleteResult);
        },
        NoticeStatusChangeFinish(result) {
          this.socketStore.setDeleteStatus(JSON.parse(result[0]));
        },
        NoticeStockZeroResult(result) {
          const log = JSON.parse(result);
          this.socketStore.setSoldOutLog(log);
        }
      }
    });
  }
};
