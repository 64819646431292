<template>
  <div class="pagenation_container">
    <button class="prev_btn" @click="movePrev">
      <img src="/assets/images/common/prev_btn.svg" alt="prev_btn" />
    </button>
    <div class="pages">
      <input type="number" class="currentPage" v-model="inputValue" />
      <p class="maxPage">/ {{ $props.maxPage }}</p>
    </div>
    <button class="next_btn" @click="moveNext">
      <img src="/assets/images/common/next_btn.svg" alt="next_btn" />
    </button>
    <button class="movePage_btn" @click="movePage">이동</button>
  </div>
</template>

<script>
export default {
  props: {
    nowPage: {
      type: Number,
      default: 0
    },
    maxPage: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      inputValue: 1
    };
  },
  methods: {
    movePrev() {
      if (this.currentPage === 1) {
        this.common.setToast('purple', 'info', '첫번째 페이지입니다.');
        return;
      }
      this.currentPage--;
    },
    moveNext() {
      if (this.currentPage === this.$props.maxPage) {
        this.common.setToast('purple', 'info', '마지막 페이지입니다.');
        return;
      }
      this.currentPage++;
    },
    movePage() {
      if (this.inputValue > this.$props.maxPage) {
        this.common.setToast('purple', 'info', `최대페이지는 ${this.$props.maxPage}페이지입니다.`);
        return;
      }
      if (this.inputValue < 1) {
        this.common.setToast('purple', 'info', '정상적인 페이지를 조회해주세요.');
      }
      this.currentPage = Number(this.inputValue);
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.$props.nowPage;
      },
      set(number) {
        this.$emit('movePage', number);
      }
    }
  },
  watch: {
    currentPage(n) {
      this.inputValue = n;
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/style/common/pagination.scss';
</style>