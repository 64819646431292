<template>
  <div class="goTop_conatiner" @click="goTopScroll">
    <img src="/assets/images/app/layout/goTop.svg" alt="goTop" />
  </div>
</template>

<script>
export default {
  methods: {
    goTopScroll() {
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style lang="scss">
.goTop_conatiner {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid $line;
  position: fixed;
  right: 30px;
  bottom: 100px;
  z-index: 75;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
}
</style>