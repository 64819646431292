<template>
  <nav class="sidebar">
    <ul class="nav_list">

      <template v-for="(group, groupIndex) in navList">
        <div class="nav_group_header" v-bind:key="'group-' + groupIndex">
        <h3>{{ group.group }}</h3>
        <div class="separator-line"></div>
        </div>
        <li
            class="nav"
            v-for="(nav, navIndex) in group.items"
            :key="'nav-' + groupIndex + '-' + navIndex"
            :class="{ selected: $route.name === nav.routeName }"
        >
          <router-link :to="`/app/${nav.link}`" v-if="nav.routeName !== '#'">
            <SVGComponent :name="nav.image"/>
            <p>{{ nav.name }}</p>
          </router-link>
          <a v-else :href="nav.link" target="_blank">
            <SVGComponent :name="nav.image"/>
            <p>{{ nav.name }}</p>
          </a>
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
import SVGComponent from '@/components/common/SVGComponent';

export default {
  components: {
    SVGComponent
  },
  data() {
    return {
      navList: [
        {
          group: "상품",
          items: [
            {name: "일반 소싱상품관리", routeName: "일반 소싱상품관리", link: "productSetting", image: "collectSetting"},
            {name: "소싱상품수집", routeName: "소싱상품수집", link: "ProductManagement", image: "collectManagement"},
            {name: "브랜드 소싱상품관리 (준비중)", routeName: "브랜드 소싱상품관리", link: "luxProductSetting", image: "luxProductSetting"},
            {name: "마켓 상품 가져오기 (준비중)", routeName: "마켓 상품 가져오기", link: "marketProduct", image: "marketProduct"},
            {name: "등록전 상품관리(상품전송)", routeName: "등록전 상품관리(상품전송)", link: "standbyProduct", image: "standbyProduct"},
            {name: "등록 상품관리", routeName: "등록상품관리", link: "registeredProduct", image: "registProduct"},
            {name: "재고관리", routeName: "재고관리", link: "inventoryLinked", image: "stockLinakage"},
          ],
        },
        {
          group: "주문",
          items: [
            {name: "주문관리", routeName: "주문관리", link: "ordermanagement", image: "orderManagement"},
            {name: "매출통계", routeName: "매출통계", link: "statistics", image: "statistics"},
          ],
        },
        {
          group: "템플릿",
          items: [
            {name: "상품 템플릿", routeName: "상품 템플릿", link: "template/image", image: "imageTemplate"},
            {name: "마진 템플릿", routeName: "마진 템플릿", link: "template/upload", image: "uploadTemplate"},
            {name: "단어설정 템플릿", routeName: "단어설정 템플릿", link: "template/word", image: "wordTemplate"},
            {name: "마켓별 템플릿", routeName: "마켓별 템플릿", link: "template/market", image: "marketTemplate"},
            {name: "CS 템플릿", routeName: "CS 템플릿", link: "template/CSTemplate", image: "csTemplate"},
          ],
        },
        {
          group: "마이페이지",
          items: [
            {name: "환경설정", routeName: "환경설정", link: "preferences", image: "settings"},
          ],
        },
        {
          group: "고객센터",
          items: [
            {name: "상담하러가기", routeName: "#", link: "https://omarket.channel.io", image: "managementCenter"},
            {name: "오늘의 마켓 오픈채팅", routeName: "#", link: "https://open.kakao.com/o/gB4vnr5g", image: "kakaotalk"},
            {name: "오늘의 마켓 카페", routeName: "#", link: "https://cafe.naver.com/hlccafe", image: "naverCafe"},
            {
              name: "사용자 가이드",
              routeName: "#",
              link: "https://www.notion.so/154e8c3bbf37802aad9be8232d11abd8?v=cec44187139e452295f58aa393e066cd",
              image: "userGuide"
            },
          ],
        },
      ]
    };
  }
};
</script>

<style lang="scss">
@import '@/assets/style/app/layout/sidebar.scss';
</style>