<template>
  <div>
    <!-- 다이나믹컴포넌트로 리팩토링 必 -->
    <svg_luxProductSetting v-if="$props.name === 'luxProductSetting'" />
    <svg_marketProduct v-if="$props.name === 'marketProduct'" />
    <svg_collectSetting v-if="$props.name === 'collectSetting'" />
    <svg_collectManagement v-else-if="$props.name === 'collectManagement'" />
    <svg_imageTemplate v-else-if="$props.name === 'imageTemplate'" />
    <svg_kakaotalk v-else-if="$props.name === 'kakaotalk'" />
    <svg_managementCenter v-else-if="$props.name === 'managementCenter'" />
    <svg_marketTemplate v-else-if="$props.name === 'marketTemplate'" />
    <svg_naverCafe v-else-if="$props.name === 'naverCafe'" />
    <svg_orderManagement v-else-if="$props.name === 'orderManagement'" />
    <svg_registProduct v-else-if="$props.name === 'registProduct'" />
    <svg_settings v-else-if="$props.name === 'settings'" />
    <svg_standbyProduct v-else-if="$props.name === 'standbyProduct'" />
    <svg_statistics v-else-if="$props.name === 'statistics'" />
    <svg_stockLinakage v-else-if="$props.name === 'stockLinakage'" />
    <svg_userGuide v-else-if="$props.name === 'userGuide'" />
    <svg_wordTemplate v-else-if="$props.name === 'wordTemplate'" />
    <svg_uploadTemplate v-else-if="$props.name === 'uploadTemplate'" />
    <svg_csTemplate v-else-if="$props.name === 'csTemplate'" />
  </div>
</template>

<script>
import svg_marketProduct from '@/components/svg/layout/svg_marketProduct';
import svg_luxProductSetting from '@/components/svg/layout/svg_luxProductSetting';
import svg_collectSetting from '@/components/svg/layout/svg_collectSetting';
import svg_collectManagement from '@/components/svg/layout/svg_collectManagement';
import svg_imageTemplate from '@/components/svg/layout/svg_imageTemplate';
import svg_kakaotalk from '@/components/svg/layout/svg_kakaotalk';
import svg_managementCenter from '@/components/svg/layout/svg_managementCenter';
import svg_marketTemplate from '@/components/svg/layout/svg_marketTemplate';
import svg_naverCafe from '@/components/svg/layout/svg_naverCafe';
import svg_orderManagement from '@/components/svg/layout/svg_orderManagement';
import svg_registProduct from '@/components/svg/layout/svg_registProduct';
import svg_settings from '@/components/svg/layout/svg_settings';
import svg_standbyProduct from '@/components/svg/layout/svg_standbyProduct';
import svg_statistics from '@/components/svg/layout/svg_statistics';
import svg_stockLinakage from '@/components/svg/layout/svg_stockLinakage';
import svg_userGuide from '@/components/svg/layout/svg_userGuide';
import svg_wordTemplate from '@/components/svg/layout/svg_wordTemplate';
import svg_uploadTemplate from '@/components/svg/layout/svg_uploadTemplate';
import svg_csTemplate from '@/components/svg/layout/svg_csTemplate';

export default {
  components: {
    svg_luxProductSetting,
    svg_marketProduct,
    svg_collectSetting,
    svg_collectManagement,
    svg_imageTemplate,
    svg_kakaotalk,
    svg_managementCenter,
    svg_marketTemplate,
    svg_naverCafe,
    svg_orderManagement,
    svg_registProduct,
    svg_settings,
    svg_standbyProduct,
    svg_statistics,
    svg_stockLinakage,
    svg_userGuide,
    svg_wordTemplate,
    svg_uploadTemplate,
    svg_csTemplate
  },
  props: {
    name: {
      type: String,
      default: ''
    }
  }
};
</script>
