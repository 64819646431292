<template>
  <div>
    <transition-group name="toast" class="toast_wrap">
      <div
        class="toast"
        v-for="(toast, index) in common.toastList"
        :key="toast.color + index"
        :class="toast.color"
      >
        <div class="toast_icon">
          <svg_good v-if="toast.icon === 'good'" />
          <svg_discontent v-else-if="toast.icon === 'discontent'" />
          <svg_info v-else-if="toast.icon === 'info'" />
          <svg_smile v-else-if="toast.icon === 'smile'" />
          <svg_scissors v-else-if="toast.icon === 'scissors'" />
        </div>

        <div class="toast_message">
          {{ toast.text }}
        </div>
<!--
        <button class="toast_close" @click="common.toastList.splice(index, 1)">✖</button>
-->
      </div>
    </transition-group>
  </div>
</template>



<script>
import svg_discontent from '@/components/svg/toast/svg_discontent';
import svg_good from '@/components/svg/toast/svg_good';
import svg_info from '@/components/svg/toast/svg_info';
import svg_smile from '@/components/svg/toast/svg_smile';
import svg_scissors from '@/components/svg/toast/svg_scissors';
export default {
  components: {
    svg_discontent,
    svg_good,
    svg_info,
    svg_smile,
    svg_scissors
  }
};
</script>

<style lang="scss">
@import '@/assets/style/common/toast.scss';
</style>
