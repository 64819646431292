<template>
  <section class="header_wrap">
    <div class="side_logo">
      <router-link to="/app">
        <img
          class="selected"
          src="/assets/images/common/logo.png"
          alt="logo"
          width="130"
        />
      </router-link>
    </div>
    <div class="header_content">
      <div class="left_side">
        <div class="expire_date">
          <p class="remainTime">{{ remainTime }}</p>

        </div>
<!--        <div class="search_user">
          <input
            type="text"
            class="custom_code_number"
            placeholder="개인통관부호번호"
            v-model="pcc"
          />
          <input type="text" class="username" placeholder="성명" v-model="receiverName" />
          <input type="text" class="phone" placeholder="휴대폰번호" v-model="receiverTelNo" />
          <button class="search_button" @click="checkPcc">조회</button>
        </div>-->
      </div>
      <div class="right_side">
        <div class="greeting" v-if="userName"> {{ userName }}님, 환영 합니다. 즐거운 하루 되세요. </div>
        <button class="approval">
          <router-link to="/plan"> 결제하기 </router-link>
        </button>
        <button class="balance" @click="openDepositDetail = true">
          <span>포인트</span>
          <p>
            <strong>{{ depositInfo?.deposit.toLocaleString() }}</strong>
            원
          </p>
        </button>
        <button class="alert" @click="openAlert()">
          <div class="svg_container">
            <svg_alert />
            <div class="badge" v-if="!alert">.</div>
          </div>
          <div class="svg_container">
            <svg_work_status />
            <div class="badge" v-if="!alert && !visitUpload && socketStore.status !== 'WAIT'">
              .
            </div>
          </div>
        </button>
        <button class="logout" @click="setLogout">
          <img src="/assets/images/app/layout/logout.svg" alt="logout" title="로그아웃" />
        </button>
      </div>
    </div>

    <Alert v-if="alert" @closeAlert="closeAlert" />
    <DepositDetail
      v-if="openDepositDetail"
      :depositInfo="depositInfo"
      @closeDepositDetail="closeDepositDetail"
      v-click-outside="closeDepositDetail"
    />
    <PccToast :toastList="toastList" @removeToast="removeToast" />
  </section>
</template>

<script>
import dayjs from 'dayjs';
import Alert from '../components/Alert';
import DepositDetail from '../components/DepositDetail';
import PccToast from '../components/PccToast';
import svg_alert from '@/components/svg/svg_alert';
import svg_work_status from '@/components/svg/svg_work_status';
import { accountAPI, dashboardApi } from '@/api';

export default {
  components: {
    Alert,
    DepositDetail,
    PccToast,
    svg_alert,
    svg_work_status
  },
  data() {
    return {
      remainTime: '확인 중입니다.',
      alert: false,
      visitUpload: false,
      depositInfo: null,
      openDepositDetail: false,
      pcc: '',
      receiverName: '',
      receiverTelNo: '',
      toastList: [],
      userName: ''
    };
  },
  created() {
    this.updateRemainTime();
    this.getDepositInfo();
    if (this.$route.query['ResultCode'] === '3001') {
      this.checkPaymentAfter();
    }
    setInterval(() => this.updateRemainTime(), 1000);
  },
  mounted() {
    const cookieUserName = this.getCookie('userName');

    if (cookieUserName) {
      this.userName = cookieUserName;
    } else {
      const { user } = this.authStore;
      this.userName = user.userName.trim() ? user.userName : '회원';
      this.setCookie('userName', this.userName, 1);
    }
  },
  methods: {
    updateRemainTime() {
      const expireDate = this.authStore?.user?.expireDate;

      if (expireDate === undefined || expireDate === null) {
        return;
      }
      const time = dayjs(expireDate).diff(dayjs(), 'second');
      const day = Math.floor(time / (60 * 60 * 24));
      const hour = Math.floor(time / (60 * 60) - day * 24);
      const minutes = Math.floor(time / 60 - day * 60 * 24 - hour * 60);
      const seconds = Math.floor(time - day * 60 * 60 * 24 - hour * 60 * 60 - minutes * 60);
      if (time <= 0) {
        this.remainTime = '기간이 만료되었습니다.';
        return;
      }
      this.remainTime = `사용기간 ${day}일 ${hour}시간 ${minutes}분 ${seconds}초 남았습니다.`;
    },
    async getDepositInfo() {
      const res = await accountAPI.GetUserRestrics();
      this.depositInfo = res.data;
    },
    openAlert() {
      this.alert = !this.alert;
      this.visitUpload = true;
    },
    closeAlert() {
      this.alert = false;
    },
    closeDepositDetail() {
      this.openDepositDetail = false;
    },
    checkPaymentAfter() {
      this.common.setToast('blue', 'good', '포인트 충전이 완료되었습니다.');
      this.openDepositDetail = true;
    },
    setCookie(name, value, days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      const expires = "expires=" + date.toUTCString();
      document.cookie = `${name}=${value}; ${expires}; path=/`;
    },
    getCookie(name) {
      const nameEQ = name + "=";
      const cookies = document.cookie.split(';');
      for (let cookie of cookies) {
        while (cookie.charAt(0) === ' ') cookie = cookie.substring(1);
        if (cookie.indexOf(nameEQ) === 0) {
          return cookie.substring(nameEQ.length, cookie.length);
        }
      }
      return null;
    },
    async checkPcc() {
      if (this.pcc === '' || this.receiverName === '' || this.receiverTelNo === '') {
        this.toastList.unshift({ color: 'yellow', icon: 'info', text: '입력정보를 확인해주세요.' });
        return;
      }
      const res = await dashboardApi.CheckPcc({
        pcc: this.pcc,
        receiverName: this.receiverName,
        receiverTelNo: this.receiverTelNo
      });

      if (res.status !== 'Success') {
        this.toastList.unshift({
          color: 'yellow',
          icon: 'info',
          text: res.message ?? '통관번호 조회를 실패했습니다.'
        });
        return;
      }
      this.toastList.unshift({ color: 'blue', icon: 'good', text: '통관번호가 일치합니다.' });
    },
    removeToast() {
      this.toastList.shift();
    },
    deleteCookie(cookieName) {
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    },
    setLogout() {
      this.authStore.logout();
      this.deleteCookie('userName');
      this.templateStore.clearTemplate();
    }
  },
  watch: {
    'socketStore.collectList'(newValue, oldValue) {
      if (oldValue.length === 0 && newValue.length > 0) {
        this.visitUpload = false;
        this.socketStore.progress = 0;
      }
    },
    'socketStore.uploadList'(newValue, oldValue) {
      if (oldValue.length === 0 && newValue.length > 0) {
        this.visitUpload = false;
        this.socketStore.progress = 0;
      }
    },
    'socketStore.finish'(status) {
      if (status === 'UPLOAD' || status === 'COLLECT') {
        this.common.setToast('blue', 'good', '작업이 완료되었습니다. 알림창을 확인해주세요.');
      }
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/style/app/layout/header.scss';
</style>
