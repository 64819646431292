<template>
  <div class="dialog_background">
    <div class="dialog_container">
      <img
        v-if="dialog.icon !== null && dialog.icon !== undefined"
        class="alert_logo"
        :src="`/assets/images/common/dialog/alert_${dialog.icon}.png`"
        alt="alert_logo"
        width="40"
        height="40"
      />

      <div
        class="title_group"
        :style="{ marginBottom: titleMargin, fontSize: common.dialog.title_size + 'px' }"
      >
        <p class="title" v-if="dialog.title !== null && dialog.title !== undefined">
          <span v-for="(line, index) in dialog.title.split('\n')" :key="index">
          {{ line }} <br>
        </span>
        </p>
        <p class="info" v-if="dialog.info !== null && dialog.info !== undefined">
           <span v-for="(line, index) in dialog.info.split('\n')" :key="index">
          {{ line }} <br>
           </span>
        </p>
      </div>

      <p class="subTitle" v-if="dialog.subTitle !== null && dialog.subTitle !== undefined">
         <span v-for="(line, index) in dialog.subTitle.split('\n')" :key="index">
        {{ line }} <br>
         </span>
      </p>

      <div class="buttonGroup">
        <button
          :class="!cancelButton ? 'closeButton' : 'cancelButton'"
          :style="{ width: buttonWidth }"
          @click="common.closeDialog()"
          @keypress.enter="common.closeDialog()"
        >
          {{ cancelButton ? '취소' : '닫기' }}
        </button>
        <button
          v-if="dialog.button !== null && dialog.button !== undefined"
          class="addButton"
          :class="dialog.button.color"
          @click="confirm"
        >
          {{ dialog.button.content }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    confirm() {
      this.common.dialog.button.onClick();
      this.common.closeDialog();
    }
  },
  computed: {
    dialog() {
      return this.common.dialog;
    },
    buttonWidth() {
      return this.common.dialog.button === null ? '380px' : '170px';
    },
    titleMargin() {
      return this.common.dialog.subTitle === null || this.common.dialog.subTitle === undefined
        ? '50px'
        : '17px';
    },
    cancelButton() {
      return this.common.dialog.button !== null && this.common.dialog.button !== undefined;
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/style/common/dialog.scss';
</style>
